<template>
  <div class="training">
    <el-card>
      <div class="box-card">
        <div class="left">
          <div slot="header" class="clearfix">
            <span>培训机构</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              @click="newTraining"
              >+培训机构</el-button
            >
          </div>
          <div
            v-for="item in tariningList"
            :key="item.id"
            class="tarining_item"
            :class="{ active: item.id == activeTariningId }"
            @click="toListItem(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="content">
          <!-- 信息表单 -->
          <el-form
            :model="ruleForm"
            :rules="listrule"
            ref="ruleForm"
            label-width="100px"
            class="content_form"
          >
            <el-form-item label="名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="简介" prop="introduction">
              <el-input v-model="ruleForm.introduction"></el-input>
            </el-form-item>
            <el-form-item label="城市" prop="city">
              <el-input v-model="ruleForm.city"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="phone">
              <el-input maxlength="11" v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="负责人" prop="leader_id">
              <el-select
                v-model="ruleForm.leader_id"
                placeholder="请选择负责人"
              >
                <el-option
                  v-for="item in dyList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="!activeTariningId">
              <el-button type="primary" @click="submitForm('ruleForm', 'add')"
                >立即添加</el-button
              >
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
            <el-form-item v-else>
              <el-button
                type="primary"
                @click="submitForm('ruleForm', 'modify')"
                >保存修改</el-button
              >
            </el-form-item>
          </el-form>

          <div class="dy_content">
            <div class="add_dy_account">
              <span @click="addDyAcount">
                <i class="el-icon-circle-plus-outline"></i>添加达人
              </span>

              <span class="del-dy-acount" @click="delDyAcount">删除机构</span>
            </div>

            <div class="dy_list" v-if="dyList.length > 0">
              <div v-for="item in dyList" class="dy_item" :key="item.id">
                <div class="dy_image">
                  <img
                    :src="
                      item.avatar ||
                      'https://eggrj.oss-cn-hangzhou.aliyuncs.com/chart_log/1633747052266.png'
                    "
                    class="avatar"
                  />
                </div>
                <div class="name_tag t4">
                  {{ item.name
                  }}<span class="tag">{{
                    item.role_name ? item.role_name : '暂无'
                  }}</span>
                </div>
                <div class="tiktok_id t4">
                  抖音: {{ item.tiktok_id ? item.tiktok_id : '-' }}
                </div>
                <div class="phone t4">
                  电话：{{ item.phone ? item.phone : '-' }}
                </div>
                <div class="cartoty t4">
                  分类：{{
                    item.service_type == 1
                      ? '设计师'
                      : item.service_type == 3
                      ? '安装队'
                      : item.service_type == 4
                      ? '材料仓'
                      : item.service_type == 5
                      ? '门店'
                      : item.service_type == 7
                      ? '软件'
                      : item.service_type == 8
                      ? '设备'
                      : '-'
                  }}
                </div>
                <span @click="editTalentFun(item)" class="edit t4"
                  ><i class="el-icon-edit"></i>编辑</span
                >

                <span class="del" @click="delTalentFun(item)">删除达人</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      title="添加达人"
      :visible.sync="handleDialogShow"
      width="30%"
    >
      <div class="handleDialog_wrap">
        <el-form
          :model="addruleForm"
          :rules="rules"
          ref="addruleForm"
          label-width="120px"
          class="addruleForm"
        >
          <el-form-item label="名称" prop="dy_name">
            <el-input
              v-model="addruleForm.dy_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="dy_phone">
            <el-input
              v-model="addruleForm.dy_phone"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="抖音号" prop="dy_account">
            <el-input
              v-model="addruleForm.dy_account"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="service_type">
            <el-select v-model="addruleForm.service_type" placeholder="请选择">
              <el-option
                v-for="i in typeList"
                :key="i.value"
                :label="i.label"
                :value="i.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签" prop="role_name">
            <el-input
              v-model="addruleForm.role_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片上传" prop="dy_upload">
            <el-upload
              class="upload-demo"
              action="#"
              accept="image/jpeg"
              :before-upload="beforeCadUpload"
              :http-request="picUpload"
              :file-list="picList"
              :before-remove="beforeRemove"
            >
              <div class="upload-btn">
                <i class="lbl lbl-upload"></i>
                <span>点击上传</span>
              </div>
              <div slot="tip" class="el-upload__tip">仅支持上传jpg格式文件</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel('addruleForm')">取 消</el-button>
        <el-button type="primary" @click="sureBtn('addruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑达人 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑达人"
      :visible.sync="editShowModel"
      width="30%"
    >
      <div class="handleDialog_wrap">
        <el-form
          :model="editruleForm"
          :rules="editRules"
          ref="editruleForm"
          label-width="120px"
          class="addruleForm"
        >
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="editruleForm.name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="editruleForm.phone"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="抖音号" prop="dy_account">
            <el-input
              v-model="editruleForm.dy_account"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="service_type">
            <el-select v-model="editruleForm.service_type" placeholder="请选择">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签" prop="role_name">
            <el-input
              v-model="editruleForm.role_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片上传" prop="dy_upload">
            <el-upload
              class="upload-demo"
              action="#"
              accept="image/jpeg"
              :before-upload="beforeCadUpload"
              :http-request="picUpload"
              :file-list="picList"
              :before-remove="beforeRemove"
            >
              <div class="upload-btn">
                <i class="lbl lbl-upload"></i>
                <span>点击上传</span>
              </div>
              <div slot="tip" class="el-upload__tip">仅支持上传jpg格式文件</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel('editruleForm')">取 消</el-button>
        <el-button type="primary" @click="sureEditBtn('editruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import upload from '../../utils/uploadOss'

const validatePhone = (rule, value, callback) => {
  if (value === '') {
    // callback(new Error("请输入手机号"));
    // 不用做验证，有内容才做手机号验证
    callback()
  } else {
    if (!/^1[3456789]\d{9}$/.test(value)) {
      callback(new Error('请输入正确的手机号'))
    } else {
      callback()
    }
  }
}
export default {
  data() {
    return {
      // 分类列表
      typeList: [
        { value: 0, label: '请选择' },
        {
          value: 5,
          label: '门店',
        },
        {
          value: 1,
          label: '设计师',
        },
        {
          value: 4,
          label: '材料仓',
        },
        {
          value: 3,
          label: '安装队',
        },
        {
          value: 7,
          label: '软件',
        },
        {
          value: 8,
          label: '设备',
        },
      ],
      // 图片上传
      imageUrl: '',
      // 选中的培训机构
      activeTariningId: '',
      // 表单数据
      ruleForm: {
        name: '',
        introduction: '',
        city: '',
        phone: '',
        //负责人
        leader_id: '',
      },
      editruleForm: {
        name: '',
        phone: '',
        dy_account: '',
        service_type: 0,
        role_name: '',
        dy_avatar: '',
      },
      // 添加dy弹窗
      addruleForm: {
        service_type: 0,
        dy_phone: '',
        dy_account: '',
        dy_name: '',
        role_name: '',
      },
      listrule: {
        city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        introduction: [
          { required: true, message: '请输入简介', trigger: 'blur' },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            message: '请输入手机号',
            trigger: 'blur',
          },
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
      rules: {
        dy_name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        dy_phone: [
          {
            required: true,
            validator: validatePhone,
            message: '请输入注册抖音手机号',
            trigger: 'blur',
          },
        ],
        dy_account: [
          { required: true, message: '请输入抖音账号', trigger: 'blur' },
        ],
      },
      editRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        phone: [
          {
            required: true,
            validator: validatePhone,
            message: '请输入注册抖音手机号',
            trigger: 'blur',
          },
        ],
        dy_account: [
          { required: true, message: '请输入抖音账号', trigger: 'blur' },
        ],
      },
      // 培训机构列表
      tariningList: [],
      // 抖音列表展示
      dyList: [],
      editShowModel: false,
      // 打开添加达人弹窗
      handleDialogShow: false,
      // 图片上传
      picture_list: [],
      picUrlList: [],
    }
  },
  watch: {
    picture_list: {
      handler: function (val, oldval) {
        this.picUrlList = []
        for (let i = 0; i < val.length; i++) {
          this.picUrlList.push(val[i].res)
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['userInfo']),
    picList() {
      return this.picture_list
    },
  },
  created() {
    this.getList()
  },
  methods: {
    //选择这负责人
    // 编辑达人
    editTalentFun(item) {
      console.log('item,', item)
      this.editShowModel = true
      this.editruleForm.name = item.name
      this.editruleForm.phone = item.phone
      this.editruleForm.dy_account = item.tiktok_id
      this.editruleForm.service_type = item.service_type
      this.editruleForm.role_name = item.role_name
      this.picUrlList[0] = item.avatar
    },
    //删除达人
    delTalentFun(item) {
      this.$confirm('此操作将永久删除该达人, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.delService({ service_id: [item.id] }, (res) => {
            if (res.status == 1) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.getInfo()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    beforeCadUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      if (!isJPG) {
        this.$message.error('上传头像只能是 JPG 格式!')
      }
      return isJPG
    },
    beforeRemove(file, fileList) {
      if (file && file.status === 'success') {
        this.$confirm(`确定移除 ${file.name}？`, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const i = this.picture_list.findIndex((x) => x.name === file.name)
            this.picture_list.splice(i, 1)
            return
          })
          .catch(() => {
            // this.picture_list = fileList
            // console.log('---取消-----',this.picture_list)
          })
        return false // 阻止一点击删除按钮 就删除文件 -- 渲染的问题
      }
    },
    picUpload(e) {
      console.log('2222222222', e)
      upload.uploadVideoToOss(
        e.file,
        (res) => {
          console.log(e.file, res)
          this.picture_list.push({ name: e.file.name, res })
        },
        () => {}
      )
    },
    // 新建培训机构
    newTraining() {
      this.activeTariningId = ''
      // 表单数据
      this.ruleForm = {
        name: '',
        introduction: '',
        city: '',
        phone: '',
        leader_id: '',
        agency_id: 0,
      }
      this.dyList = []
      // 添加dy弹窗
      this.addruleForm = {
        dy_phone: '',
        dy_account: '',
        dy_name: '',
        role_name: '',
      }
      this.$refs.ruleForm.resetFields()
      this.$refs.addruleForm.resetFields()
    },
    // 获取培训机构列表
    getList() {
      // if (this.tariningList) this.activeTariningId = this.tariningList[0].id
      if (window.localStorage.getItem('phone') != '13182776666') {
        this.$request.getAgencyList(
          'page=1&limit=999&is_backstage=1',
          (res) => {
            console.log(res.data, 'msg')
            if (res.status === 0) {
              this.$message.error(res.msg)
            } else {
              this.tariningList = res.data
              this.activeTariningId = res.data[0].id
              this.getInfo()
            }
          }
        )
      } else {
        this.$request.getAgencyListSpec(
          'phone=13182776666&limit=999&page=1',
          (res) => {
            console.log('获取特殊培训机构-------', res)
            if (res.status) {
              this.tariningList = res.data.data
              this.activeTariningId = res.data.data[0].id
              this.getInfo()
            }
          }
        )
      }
    },
    // 获取培训机构详情
    getInfo() {
      this.$request.getAgencyInfo(this.activeTariningId, (res) => {
        console.log(res.data, 'wd')
        if (res.status) {
          this.$refs.ruleForm.resetFields()
          this.ruleForm.name = res.data.name
          this.ruleForm.introduction = res.data.introduction
          this.ruleForm.city = res.data.city
          this.ruleForm.phone = res.data.phone
          this.ruleForm.leader_id = res.data.leader_id
          this.dyList = res.data.represent
          console.log(' this.dyList', this.dyList)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //修改机构
    modify() {
      console.log(this.ruleForm)
    },
    // 创建按钮
    submitForm(formName, status = 'modify') {
      console.log('this.ruleForm', this.ruleForm)
      if (status == 'modify') {
        this.ruleForm.agency_id = this.activeTariningId
      } else {
        this.ruleForm.agency_id = 0
        delete this.ruleForm.leader_id
      }
      if (!this.ruleForm.leader_id) {
        delete this.ruleForm.leader_id
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //新建培训机构
          this.$request.addSeeyardAgency(this.ruleForm, (res) => {
            this.getList()
            // this.activeTariningId = res.data.id
            this.$message.success('新建成功!')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置按钮
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 点击培训机构切换
    toListItem(item) {
      this.activeTariningId = item.id
      this.getInfo()
    },
    // 添加dy账号
    addDyAcount() {
      console.log('添加dy账号')
      this.handleDialogShow = true
    },
    //删除机构
    delDyAcount() {
      this.$confirm('此操作将永久删除该机构, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.delAgencyInfo(
            { agency_id: [this.activeTariningId] },
            (res) => {
              if (res.status == 1) {
                this.$message.success('删除成功')
                this.getList()
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 取消添加达人弹窗
    cancel(name) {
      this.$refs[name].resetFields()
      if (name == 'editruleForm') {
        this.editShowModel = false
      } else {
        this.handleDialogShow = false
        this.picUrlList = []
        this.picture_list = []
      }
    },
    // 编辑-确定添加达人弹窗
    sureEditBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //新建培训机构
          console.log('确定添加达人弹窗', this.picUrlList)
          let payload = {
            department_id: this.activeTariningId,
            phone: this.editruleForm.phone,
            dy_avatar: this.picUrlList[0],
            dy_account: this.editruleForm.dy_account,
            name: this.editruleForm.name,
            role_name: this.editruleForm.role_name,
            service_type: this.editruleForm.service_type,
          }
          this.$request.seeyardBindDyAccount(payload, (res) => {
            if (res.status === 0) {
              this.$message.error(res.msg)
            } else {
              this.editShowModel = false
              this.$message.success('编辑达人成功！')
            }
          })
          // 获取抖音授权用户信息
          this.getInfo()
          // this.$request.getDyUserInfo(
          //   { phone: this.editruleForm.phone },
          //   (res) => {
          //     console.log(res.data)
          //     if (res.status === 0) {
          //       this.$message.error(res.msg)
          //       return
          //     } else {
          //     }
          //   }
          // )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 添加-确定添加达人弹窗
    sureBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //新建培训机构
          console.log('确定添加达人弹窗', this.picUrlList)
          let payload = {
            department_id: this.activeTariningId,
            phone: this.addruleForm.dy_phone,
            dy_avatar: this.picUrlList[0],
            dy_account: this.addruleForm.dy_account,
            name: this.addruleForm.dy_name,
            role_name: this.addruleForm.role_name,
            service_type: this.addruleForm.service_type,
          }
          this.$request.seeyardBindDyAccount(payload, (res) => {
            console.log(res.data, 'asdfasdf')
            if (res.status === 0) {
              this.$message.error(res.msg)
            } else {
              this.$message.success('添加达人成功！')
            }
          })
          // 获取抖音授权用户信息
          this.addruleForm = {
            dy_phone: '',
            dy_account: '',
            dy_name: '',
            role_name: '',
          }
          this.picUrlList = []
          this.picture_list = []
          this.handleDialogShow = false
          this.getInfo()
          // this.$request.getDyUserInfo(
          //   { phone: this.addruleForm.dy_phone },
          //   (res) => {
          //     console.log(res.data)
          //     if (res.status === 0) {
          //       this.$message.error(res.msg)
          //       return
          //     } else {
          //     }
          //   }
          // )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.box-card {
  display: flex;
  align-items: flex-start;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: calc(15% + 15px);
    width: 1px;
    height: 100%;
    top: 0;
    background: #ccc;
  }
  .left {
    width: 15%;
    min-height: 100%;
    margin-right: 30px;
    .clearfix {
      margin-bottom: 16px;
      span {
        font-size: 18px;
        font-weight: bold;
      }
      .el-button {
        cursor: pointer;
      }
    }
    .tarining_item {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px;
      color: rgba(0, 0, 0, 0.6);
      &.active {
        background: #f3f3f3;
        color: #000;
      }
    }
  }
  .content {
    flex: 1;
    .content_form {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #ccc;
      .el-form-item {
        width: 40%;
        margin-right: 10%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    .dy_content {
      margin-top: 20px;
      .dy_list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0 45px;
        .dy_item {
          width: 15%;
          margin-right: 1%;
          margin-bottom: 16px;
          .dy_image {
            width: 100px;
            height: 100px;
            aspect-ratio: 1;
            .avatar {
              width: 100px;
              height: 100px;
            }
          }
          .t4 {
            margin-bottom: 5px;
          }
          .name_tag {
            display: flex;
            align-items: center;
            .tag {
              margin-left: 5px;
              color: rgb(236, 123, 47);
              border: 1px solid rgb(236, 123, 47);
              padding: 0px 8px;
            }
          }
          .edit {
            cursor: pointer;
            color: rgb(109, 126, 255);
          }
          .del {
            color: #f56c6c;
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
      .add_dy_account {
        cursor: pointer;
        padding-left: 45px;
        margin-top: 20px;
        font-size: 18px;
        display: flex;
        align-items: center;
        i {
          color: #409eff;
          margin-right: 6px;
          font-size: 20px;
        }
        .del-dy-acount {
          color: #f56c6c;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
